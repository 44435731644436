// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-helpme-js": () => import("./../../../src/pages/helpme.js" /* webpackChunkName: "component---src-pages-helpme-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-white-dwarf-magazine-102-alternate-cover-js": () => import("./../../../src/pages/white-dwarf-magazine-102-alternate-cover.js" /* webpackChunkName: "component---src-pages-white-dwarf-magazine-102-alternate-cover-js" */),
  "component---src-templates-whitedwarfissue-js": () => import("./../../../src/templates/whitedwarfissue.js" /* webpackChunkName: "component---src-templates-whitedwarfissue-js" */)
}

